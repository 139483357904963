<template>
  <div>
    <b-form-group
      label="Texto"
      label-for="input-text"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-textarea
        id="input-text"
        v-model="$v.likert_assertion.text.$model"
        :state="validateState('text')"
        aria-describedby="input-text-feedback"
        size="sm"
      ></b-form-textarea>
      <b-form-invalid-feedback id="input-text-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Posición"
      label-for="input-order"
      label-cols="0"
      label-cols-sm="3"
      class="m-0 p-0"
    >
      <b-form-input
        id="input-order"
        type="number"
        v-model="$v.likert_assertion.order.$model"
        :state="validateState('order')"
        aria-describedby="input-order-feedback"
        size="sm"
      ></b-form-input>
      <b-form-invalid-feedback id="input-order-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(likert_assertion.id)"
        class="col"
        style="text-align: left"
      >
        <b-button class="mr-1" size="sm" variant="danger" @click="askForDelete"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="save">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { generateUniqueId, toast } from "@/utils/utils";

export default {
  name: "LikertAssertionForm",
  mixins: [validationMixin],
  props: {
    LikertAssertion: {
      type: Object,
    },
    likert_scale_id: {
      type: Number,
      required: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      likert_assertion: {
        id: this.LikertAssertion ? this.LikertAssertion.id : generateUniqueId(),
        likert_scale: this.LikertAssertion
          ? this.LikertAssertion.likert_scale
          : this.likert_scale_id,
        text: this.LikertAssertion ? this.LikertAssertion.text : "",
        order: this.LikertAssertion ? this.LikertAssertion.order : null,
      },
    };
  },
  validations() {
    return {
      likert_assertion: {
        text: { required },
        order: { required },
      },
    };
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.likert_assertion[name];
      return $dirty ? !$error : null;
    },
    save() {
      this.$v.likert_assertion.$touch();
      if (this.$v.likert_assertion.$anyError) {
        return;
      }
      if (isNaN(this.likert_assertion.id)) this.create();
      else this.update();
    },
    create() {
      this.$restful
        .Post("/cideu/likert-assertion/", this.likert_assertion)
        .then((response) => {
          this.likert_assertion = response;
          this.$emit("created", response);
          toast("Afirmación creada.");
        });
    },
    update() {
      this.$restful
        .Put(
          `/cideu/likert-assertion/${this.likert_assertion.id}/`,
          this.likert_assertion
        )
        .then((response) => {
          this.likert_assertion = response;
          this.$emit("updated", response);
          toast("Afirmación actualizada.");
        });
    },
    askForDelete() {
      this.$swal({
        title: "¿Está seguro de que desea eliminar la Afirmación?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.delete();
        }
      });
    },
    delete() {
      this.$restful
        .Delete(`/cideu/likert-assertion/${this.likert_assertion.id}/`)
        .then(() => {
          this.$emit("deleted", this.likert_assertion);
          toast("Afirmación eliminada.");
        });
    },
  },
};
</script>

<style>
</style>